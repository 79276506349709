import React,{useState} from 'react';
import {Toast,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import { useHistory } from "react-router-dom";
function ToastMessage({Message,closeModal,isChatNotofication,hideBtn,caseId}) {
    const [t] = useTranslation();
    const history = useHistory();
    const syncDashboard = () =>{
        closeModal(false);
        let url = `${window.location.origin}/dashboard/`
        window.location.replace(url);
    }
    const handleNotificationClick = () =>{
        if(caseId){
            history.push({pathname:"/inbox",state: {caseId}});
        }else{
            history.push('/userchat');
        }
        closeModal(false);
    }
    return (
        !isChatNotofication ?
        <Toast style={oStyle} onClose={() => {
                closeModal(false);
            }} 
            show={!!Message} delay={10000} autohide
        >
            <Toast.Header>
            <strong className="mr-auto">{t('Notification')}</strong>
            <small>{t('Just now')}</small>
            </Toast.Header>
            <Toast.Body>
                {t(Message)}
                {
                    !hideBtn && 
                    <div>
                        <Button onClick={syncDashboard} className="mt-2">
                            {/* <i className="fas fa-sync"></i>  */}
                            {t('Sync Dashboard')}
                        </Button>
                    </div>
                }
            </Toast.Body>
        </Toast>:
        <Toast style={oMessageStyle} onClose={() => {
                closeModal(false);
            }} 
            show={!!Message} delay={5000} autohide
            onClick={handleNotificationClick}
        >
            <Toast.Header>
            <strong className="mr-auto">{caseId ? `${t('Case Id')}:${caseId}`:t('Notification')}</strong>
            <small>{t('Just now')}</small>
            </Toast.Header>
            <Toast.Body>
                {   caseId ? 
                    `${Message} ${t('wants to have a discussion with you about a case they raised.')} `
                    :
                    `${t('You have a new chat message from')} ${Message}`
                }
            </Toast.Body>
        </Toast>
    );
}


const oStyle = {
    position: 'fixed',
    bottom: '5px',
    right: '5px',
    zIndex:1020
}

const oMessageStyle = {
    position: 'fixed',
    bottom: '5px',
    left: '5px',
    zIndex:1020
}


export default ToastMessage;