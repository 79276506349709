import moment from "moment-timezone";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const compare = (a, b) => {
  if (a.value > b.value) {
    return -1;
  }
  if (a.value < b.value) {
    return 1;
  }
  return 0;
};

export const validURL = (str) => {
  var pattern =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return !!pattern.test(str);
};

export const validFirbaseURL = (str) => {
  var pattern = str.includes("firebasestorage");
  return pattern;
};

export const convertToLocale = (p_str, p_isAlredylocal = false) => {
  let sTime = p_isAlredylocal
    ? moment(p_str).local()
    : moment.tz(p_str, "Europe/Zurich").local();
  return sTime;
};
export const removeSpacetoLowerCase = (string) =>
  string.trim().toLowerCase().split(" ").join("");

export const scriptCheck = (value) =>
  value && value.match(/<[^>]*>/g) != null
    ? 'Incorrect expression "< or >" added as input'
    : undefined;

export const orderStatus = (p_data) => {
  const CaseOrder = {
      0: "Open",
      1: "Validated",
      2: "Declined",
      3: "Resolved",
      4: "Confirmed",
      5: "NotConfirmed",
      6: "Unable to resolve",
    },
    oStatus = {},
    aStatus = [];
  p_data.forEach(({ name, val }) => {
    oStatus[name] = val;
  });
  for (let i in CaseOrder) {
    if (oStatus[CaseOrder[i]] !== undefined) {
      aStatus.push({ name: CaseOrder[i], val: oStatus[CaseOrder[i]] });
    }
  }
  return aStatus;
};

export const filterData = (registrationInfoFilter) => {
  let aTempReg = [];
  registrationInfoFilter.forEach((e) => {
    let { children, value } = e[0];
    if (e.length === 1) {
      children = (children || []).filter(({ type }) => type === "o");
      children.forEach(({ label }) => {
        aTempReg.push({ option: label, questionId: value });
      });
    } else {
      e.slice(1).forEach(({ label, type }) => {
        if (type === "o") {
          let aSplit = value.toString().split("~");
          aTempReg.push({ option: label, questionId: aSplit[0] * 1 });
        }
      });
    }
  });
  return aTempReg;
};

export const computeOptionsRegWithDep = (
  p_aTemp,
  p_children,
  p_value,
  p_ques,
  arr
) => {
  if ((p_children && p_children.length) || p_ques) {
    p_children.forEach(
      ({ type,
        label,
        value,
        children,
        parentid,
        leveloneparentid,
        toplevelparentid,
        optionIndex
      }) => {
        
        if (type === "o") {
          let aSplit = p_ques
            ? p_ques.toString().split("~")
            : p_value.toString().split("~");
          // let aSplitServiceId = p_value;
          // if (typeof p_value === "string" && p_value.includes("~")) {
          //   aSplitServiceId = p_value.toString().split("~")[0] * 1;
          // }

          let duplicatefound = p_aTemp.find(o => 
            o.option == label &&
            o.questionId == toplevelparentid &&
            o.leveloneQuestionId == leveloneparentid
          )
          if(!duplicatefound) {
            p_aTemp.push({
              option: label,
              questionId: toplevelparentid,
              // serviceId: aSplitServiceId,
              optionIndex: optionIndex,
              // parentid: aSplitServiceId,
              // levelzeroServiceid: serviceId,
              leveloneQuestionId: leveloneparentid,
              toplevelparentid: toplevelparentid,
            });
          }
        }
        if (children && children.length > 0) {
          computeOptionsRegWithDep(p_aTemp, children, p_value, null, []);
        }
      }
    );
  } else if (p_value && arr.length == 1) {
    let { type,
      label,
      value,
      children,
      parentid,
      leveloneparentid,
      toplevelparentid,
      optionIndex 
    } = arr[0];
    // let nSurveyId = arr[0].value
    

    if (type === "o") {
      let aSplit = value.toString().split("~");
      // let aSplitServiceId = nSurveyId;
      // if (typeof nSurveyId == 'string' && nSurveyId.includes("~")) {
      //   aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
      // }

      let duplicatefound = p_aTemp.find(o => 
        o.option == label && 
        o.questionId == toplevelparentid &&
        o.leveloneQuestionId == leveloneparentid
      )
      if(!duplicatefound) {
        p_aTemp.push({
          option: label,
          questionId: toplevelparentid,
          // serviceId: aSplitServiceId,
          optionIndex: optionIndex,
          // parentid: aSplitServiceId,
          // levelzeroServiceid: serviceId,
          leveloneQuestionId: leveloneparentid,
          toplevelparentid: toplevelparentid,
        });
      }

    }
  }
};


export const filterRegWithDep = (p_regfilters) => {
  let aTemp = [];
  p_regfilters.slice().forEach((arr) => {
    if (arr.length === 1) {
      let { children, value } = arr[0];
      computeOptionsRegWithDep(aTemp, children, value, null, arr);
    } else {
      // let nSurveyId = arr[0].value,
      let nLen = arr.length - 1;
      // arr.slice(nLen).forEach((e) => {

      let f_option = arr.slice(nLen).filter(f => f.type === 'o')

      if(f_option && f_option.length) {
        f_option.forEach((e) => {
          let {
            type,
            label,
            value,
            children,
            parentid,
            leveloneparentid,
            toplevelparentid,
            optionIndex,
          } = e;
          
          if (type === "o") {
            let aSplit = value.toString().split("~");
            // let aSplitServiceId = nSurveyId;
            // if (typeof nSurveyId == 'string' && nSurveyId.includes("~")) {
            //   aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
            // }
            let duplicatefound = aTemp.find(o => 
              o.option == label && 
              o.questionId == toplevelparentid &&
              o.leveloneQuestionId == leveloneparentid
            )
            if(!duplicatefound) {
              aTemp.push({
                option: label,
                questionId: toplevelparentid,
                // serviceId: nSurveyId,
                optionIndex: optionIndex,
                // parentid: aSplitServiceId,
                // levelzeroServiceid: serviceId,
                leveloneQuestionId: leveloneparentid,
                toplevelparentid: toplevelparentid,
              });
            }
          }
          
        });
      } else {
        arr.forEach((e) => {
          let {
            type,
            label,
            value,
            children,
            parentid,
            leveloneparentid,
            toplevelparentid,
            optionIndex,
          } = e;
          
          if (type === "o") {
            let aSplit = value.toString().split("~");
            // let aSplitServiceId = nSurveyId;
            // if (typeof nSurveyId == 'string' && nSurveyId.includes("~")) {
            //   aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
            // }
            let duplicatefound = aTemp.find(o => 
              o.option == label && 
              o.questionId == toplevelparentid &&
              o.leveloneQuestionId == leveloneparentid
            )
            if(!duplicatefound) {
              aTemp.push({
                option: label,
                questionId: toplevelparentid,
                // serviceId: nSurveyId,
                optionIndex: optionIndex,
                // parentid: aSplitServiceId,
                // levelzeroServiceid: serviceId,
                leveloneQuestionId: leveloneparentid,
                toplevelparentid: toplevelparentid,
              });
            }
          }
          if (children && children.length > 0) {
            computeOptionsRegWithDep(aTemp, children, toplevelparentid, value);
          }
        });
      }
 
    }
  });
  return aTemp;
};

export const computeOptions = (p_aTemp, p_children, p_value, p_ques) => {
  if (p_children || p_ques) {
    p_children.forEach(({ type, children, label, value }) => {
      if (type === "o") {
        let aSplit = p_ques.toString().split("~");
        p_aTemp.push({
          option: label,
          questionId: aSplit[0] * 1,
          surveyId: p_value,
        });
      }
      if (children && children.length > 0) {
        computeOptions(p_aTemp, children, p_value, value);
      }
    });
  }
};
export const filterSurveyData = (p_surveyFilters) => {
  let aTemp = [];
  p_surveyFilters.forEach((arr) => {
    if (arr.length === 1) {
      let { children, value } = arr[0];
      computeOptions(aTemp, children, value);
    } else {
      let nSurveyId = arr[0].value,
        nLen = arr.length - 1;
      arr.slice(nLen).forEach(({ type, label, value, children }) => {
        if (type === "o") {
          let aSplit = value.toString().split("~");
          aTemp.push({
            option: label,
            questionId: aSplit[0] * 1,
            surveyId: nSurveyId,
          });
        }
        if (children && children.length > 0) {
          computeOptions(aTemp, children, nSurveyId, value);
        }
      });
    }
  });
  return aTemp;
};

export const computeOptionsService = (p_aTemp, p_children, p_value, p_ques) => {
  if (p_children || p_ques) {
    p_children.forEach(({ type, children, label, value }) => {
      if (type === "o") {
        let aSplit = p_ques
          ? p_ques.toString().split("~")
          : p_value.toString().split("~");
        let aSplitServiceId = p_value;
        if (typeof p_value === "string" && p_value.includes("~")) {
          aSplitServiceId = p_value.toString().split("~")[0] * 1;
        }
        p_aTemp.push({
          option: label,
          questionId: aSplit[0] * 1,
          serviceId: aSplitServiceId,
        });
      }
      if (children && children.length > 0) {
        computeOptionsService(p_aTemp, children, p_value, value);
      }
    });
  }
};

export const filterServiceData = (p_surveyFilters) => {
  console.log(p_surveyFilters,"p_surveyFilters")
  let aTemp = [];
  p_surveyFilters.slice().forEach((arr) => {
    console.log(arr,"arr")
    if (arr.length === 1) {
      let { children, value } = arr[0];
      computeOptionsService(aTemp, children, value);
    } else {
      let nSurveyId = arr[0].value,
        nLen = arr.length - 1;
      arr.slice(nLen).forEach(({ type, label, value, children }) => {
        if (type === "o") {
          let aSplit = value.toString().split("~");
          let aSplitServiceId = nSurveyId;
          if (nSurveyId.toString().includes("~")) {
            aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
          }
          aTemp.push({
            option: label,
            questionId: aSplit[0] * 1,
            serviceId: nSurveyId,
          });
        }
        if (children && children.length > 0) {
          computeOptionsService(aTemp, children, nSurveyId, value);
        }
      });
    }
  });
  console.log(aTemp,"aTemp")
  return aTemp;
};

export const computeOptionsServiceMap = (
  p_aTemp,
  p_children,
  p_value,
  p_ques,
  arr
) => {
  if ((p_children && p_children.length) || p_ques) {
    p_children.forEach(
      (e) => {
        let { type, children, label, value, firstlevelquestionid, serviceId } = e;
        if (type === "o") {
          let aSplit = p_ques
            ? p_ques.toString().split("~")
            : p_value.toString().split("~");
          let aSplitServiceId = p_value;
          let optionindex = aSplit[1]
          let questionid = aSplit[0] * 1
          if (typeof p_value === "string" && p_value.includes("~")) {
            aSplitServiceId = p_value.toString().split("~")[0] * 1;
          } else if (typeof value === "string" && value.includes("~")) {
            optionindex = value.toString().split("~")[2];
            questionid = value.toString().split("~")[1] * 1
          }

          p_aTemp.push({
            option: label,
            questionId: questionid,
            serviceId: aSplitServiceId,
            optionIndex: optionindex,
            parentid: aSplitServiceId,
            levelzeroServiceid: serviceId,
            leveloneQuestionId: firstlevelquestionid
              ? firstlevelquestionid.toString().split("~")[0] * 1
              : null,
          });
        }
        if (children && children.length > 0) {
          computeOptionsServiceMap(p_aTemp, children, p_value, null, []);
        }
      }
    );
  } else if (p_value && arr.length == 1) {
    let { type, label, value, firstlevelquestionid, serviceId } = arr[0];
    let nSurveyId = arr[0].value;
    if (type === "o") {
      let aSplit = value.toString().split("~");
      let aSplitServiceId = nSurveyId;
      if (typeof nSurveyId == "string" && nSurveyId.includes("~")) {
        aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
      }
      p_aTemp.push({
        option: label,
        questionId: aSplit[1] * 1,
        serviceId: aSplitServiceId,
        optionIndex: aSplit[2],
        parentid: aSplitServiceId,
        levelzeroServiceid: serviceId,
        leveloneQuestionId: firstlevelquestionid
          ? firstlevelquestionid.toString().split("~")[0] * 1
          : null,
      });
    }
  }
};

export const filterServiceDataMap = (p_surveyFilters) => {
  let aTemp = [];
  p_surveyFilters.slice().forEach((arr) => {
    if (arr.length === 1) {
      let { children, value } = arr[0];
      computeOptionsServiceMap(aTemp, children, value, null, arr);
    } else {
      let nSurveyId = arr[0].value,
        nLen = arr.length - 1;
      arr
        .slice(nLen)
        .forEach(
          (e) => {
            let {
              type,
              label,
              value,
              children,
              firstlevelquestionid,
              serviceId,
            } = e;
            if (type === "o") {
              let aSplit = value.toString().split("~");
              let aSplitServiceId = nSurveyId;
              if (typeof nSurveyId == "string" && nSurveyId.includes("~")) {
                aSplitServiceId = nSurveyId.toString().split("~")[0] * 1;
              }
              // aTemp.push({
              //   option: label,
              //   questionId: aSplit[1] * 1,
              //   serviceId: nSurveyId,
              //   optionIndex: aSplit[2],
              //   parentid: aSplitServiceId,
              //   levelzeroServiceid: serviceId,
              //   leveloneQuestionId: firstlevelquestionid
              //     ? firstlevelquestionid.toString().split("~")[0] * 1
              //     : null,
              // });
              let optionindex = aSplit[1]
              let questionid = aSplit[1] * 1

              if(aSplit && aSplit.length == 2) {
                optionindex = aSplit[1]
                questionid = aSplit[0] * 1
              } else if(aSplit && aSplit.length > 2) {
                optionindex = aSplit[2]
              }

              aTemp.push({
                option: label,
                questionId: questionid,
                serviceId: aSplitServiceId,
                optionIndex: optionindex,
                parentid: aSplitServiceId,
                levelzeroServiceid: serviceId,
                leveloneQuestionId: firstlevelquestionid
                  ? firstlevelquestionid.toString().split("~")[0] * 1
                  : null,
              });


            }
            if (children && children.length > 0) {
              computeOptionsServiceMap(aTemp, children, nSurveyId, value);
            }
          }
        );
    }
  });
  return aTemp;
};

export const computeLocationFilters = (p_data) => {
  let aFiltersLoc = [[], [], []],
    oLoc = {};
  p_data.forEach((e) => {
    e.forEach((x, i) => {
      if (aFiltersLoc[i].indexOf(x) === -1) {
        aFiltersLoc[i].push(x);
      }
    });
  });
  oLoc.country = aFiltersLoc[0];
  if (aFiltersLoc[1].length) {
    oLoc.province = aFiltersLoc[1];
  }
  if (aFiltersLoc[2].length) {
    oLoc.district = aFiltersLoc[2];
  }
  return oLoc;
};

export const getCountryNameCode = (e) => {
  let splitName = e.split("@#");
  let name = splitName[0];
  let code = splitName[1];
  return {
    name,
    code,
  };
};

export const enableConsole = (p_enabled) => {
  if (p_enabled) {
    console.log = console.log;
  } else {
    console.log = function () {};
  }
};

export const computeRegistrationDD = (p_aOptions, p_aQues) => {
  p_aQues
    .filter((x) => x.isActive === true)
    .forEach((e) => {
      let {
          dependentQuestionsList,
          question,
          name,
          questionId,
          id,
          optionMap,
          subQuestionId,
        } = e,
        oOption = {
          label: question,
          value: subQuestionId ? `${questionId}~${subQuestionId}` : questionId,
          type: "q",
        };
      if (optionMap && Object.keys(optionMap).length) {
        oOption.children = [];
        for (let k in optionMap) {
          oOption.children.push({
            value: subQuestionId
              ? `${questionId}~${subQuestionId}~${optionMap[k]}`
              : `${questionId}~${optionMap[k]}`,
            label: optionMap[k],
            type: "o",
          });
        }
      }
      if (dependentQuestionsList && dependentQuestionsList.length > 0) {
        if (!oOption.children) {
          oOption.children = [];
        }
        computeRegistrationDD(oOption.children, dependentQuestionsList);
      }
      p_aOptions.push(oOption);
    });
};
