import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import App from "./App";
import Reducer from "./reducers";
import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
/* import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/collapse'; */

// import 'font-awesome/css/font-awesome.min.css';
import "@coreui/coreui/dist/css/coreui.min.css";
import "@coreui/icons/css/coreui-icons.min.css";

import "./assets/css/style.css";
// import './assets/css/coreui_whole.css';
import "./assets/css/custom.css";
import "./assets/css/light_theme.css";
import "./assets/sass/app.scss";
import "./assets/css/theme-blue.css";
import "./assets/css/animation.css";
import "./assets/css/productfont-codes.css";
import "./assets/css/productfont-embedded.css";
import "./assets/css/productfont-ie7-codes.css";
import "./assets/css/productfont-ie7.css";
import "./assets/css/productfont.css";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "leaflet/dist/leaflet.css";
import { enableConsole } from "./helper/";

// enableConsole(false);
const store = createStore(
  Reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

/* ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
); */

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
