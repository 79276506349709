import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import loginRoutes from "./routes/loginRoutes";
import indexRoutes from "./routes/indexroutes";
import { connect } from "react-redux";
// import {signup,signin} from './helper/auth';
// import { auth , db} from "./services/firebase";
import ErrorBoundary from "./ErrorBoundary";
import NetworkDetector from "./NetworkDetector";

const loading = <div className="loading"></div>;

const App = ({ UUID, EMAIL }) => {
  const history = useHistory();
  // const communityBO = UUID ? UUID : JSON.parse(localStorage.getItem('community'));
  const [logedIn, setLoggedIn] = useState(!!UUID);
  const [communityDetails, setcommunityDetails] = useState(null);

  useEffect(() => {
    /* firebase login n signup */
    if (UUID) {
      import("./helper/auth").then(({ signin, signup }) => {
        signin(EMAIL, UUID)
          .then((e) => {
          })
          .catch((e) => {
            signup(EMAIL, UUID);
          });
      });

      /*  signin(EMAIL,UUID).then(e=>{
        console.log(e)
      }).catch(e=>{
        signup(EMAIL,UUID)
      }); */
      /*  */
    }
  }, [UUID, EMAIL]);

  const updateLoggedInStatus = () => {
    setLoggedIn(true);
  };

  const handleFirstTimeLogin = (p_data) => {
    setcommunityDetails(p_data);
  };

  /* useEffect(() =>{
    if(communityDetails && history){
      history.push('/firstTimeLogin');
    }
  },[communityDetails,history]); */

  const aIndexRoutes = indexRoutes.map((prop, key) => {
    return <Route to={prop.path} component={prop.component} key={key} />;
  });
  const aLoginRoutes = loginRoutes.map((prop, key) => {
    if (prop.redirect) {
      return <Redirect from={prop.path} to={prop.to} key={key} />;
    }
    if (prop.isFirstTime) {
      if (communityDetails) {
        return (
          <Route
            path={prop.path}
            key={key}
            render={(e) => <prop.component data={communityDetails} />}
          />
        );
      }
      return null;
    }
    return (
      <Route
        path={prop.path}
        key={key}
        render={(e) => (
          <prop.component
            onSuccess={updateLoggedInStatus}
            onFirstTimeLogin={handleFirstTimeLogin}
            communityDetails={communityDetails}
          />
        )}
      />
    );
  });

  return (
    <ErrorBoundary>
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading}>
          <Switch>
            {logedIn ? (UUID ? aIndexRoutes : null) : aLoginRoutes}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

const mapStateToProps = ({ communityData }) => {
  let { communityId, email } = communityData;
  return {
    UUID: communityId ? communityId : null,
    EMAIL: email ? email : "",
  };
};

export default connect(mapStateToProps, null)(NetworkDetector(App));
